import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import validateText from "./validateText";
import selectValues from "../config/selectvalues";
import memoizeOne from "memoize-one";
import deepEqual from "lodash/isEqual";
import InputLabel from "@material-ui/core/InputLabel";
import constants from "../util/constants";
import _ from "lodash";
export default class SelectboxComp extends Component {
  static defaultState = () => "";
  state = {
    selectedOption: null,
    gameTypeArray: [],
    isUnibetGame: false,
    unibetGameTypes: [],
  };

  collect() {
    return validateText(this.props.values);
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      const { name, values, updateValues, path, unibetData } = newprops;
      if (name === "type" && !_.isEmpty(unibetData)) {
        let gameTypes = [];
        gameTypes = unibetData.BetOffers.map((betoffer, index) => {
          return {
            label: betoffer.Criterion.Label,
            value: betoffer.Criterion.Label,
          };
        });
        // console.log("++++++++++++++++++++++++++++++++", gameTypes);
        let selectedOption = { label: newprops.values, value: newprops.values };
        this.setState({
          isUnibetGame: true,
          selectedOption,
          unibetGameTypes: gameTypes,
        });
      }
    }
  }

  filterColors = (inputValue, callback) => {
    const { name, path } = this.props;
    var selectValue = [];
    selectValue = selectValues[name];
    let optionsList = [];
    if (path.length < 2 || path[2] !== "type") {
      let memo = memoizeOne(this.calculateOptionsArray, deepEqual);
      optionsList = memo(selectValue);
      if (inputValue) {
        return optionsList.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      } else {
        optionsList = [];
      }
    } else if (path[2] === "type" && !this.state.isUnibetGame) {
      this.fetchGamesTypes(inputValue).then((options) => {
        optionsList = options;
        callback(optionsList);
      });
    }
    return optionsList;
  };

  loadOptions = (inputValue, callback) => {
    const { name, path } = this.props;
    setTimeout(() => {
      let filter = memoizeOne(this.filterColors, deepEqual);
      if (
        path.length < 2 ||
        (path[2] !== "type" && path[2] !== "team-a" && path[2] !== "team-b")
      ) {
        callback(filter(inputValue, callback));
      } else {
        filter(inputValue, callback);
      }
    }, 1000);
  };

  handleChange = (selectedOption) => {
    const { path, updateValues } = this.props;
    this.setState({ selectedOption });
    updateValues(path, selectedOption.value);
  };
  fetchGamesTypes = async (inputValue) => {
    const { path, updateValues } = this.props;
    var gameTypeProcessedArray = [];
    var gamesTypeURL = "";
    if (typeof inputValue === "undefined") {
      gamesTypeURL = `${constants.AB_URL}v1/game_types`;
      inputValue = "";
    } else {
      gamesTypeURL = `${constants.AB_URL}v1/game_types?q=${inputValue}`;
    }
    if (typeof inputValue !== "undefined") {
      await fetch(gamesTypeURL, {
        method: "get",
      })
        .then((response) => {
          return response.json();
        })
        .then((gametype) => {
          gameTypeProcessedArray = gametype.map((game, index) => {
            if (game.length == 2) {
              return {
                value: game[1],
                label: game[0],
              };
            } else {
              return [];
            }
          });
          this.setState({
            gameTypeArray: gameTypeProcessedArray,
          });
          if (gametype.length > 0 && inputValue === "") {
            setTimeout(() => {
              this.setState({
                selectedOption: gameTypeProcessedArray[0],
              });
            }, 100);
          }
        });
      return gameTypeProcessedArray;
    }
    return gameTypeProcessedArray;
  };
  calculateOptionsArray(selectValue) {
    let optionsArray = [];
    if (typeof selectValue !== "undefined" && selectValue !== null) {
      let selectKeys = Object.keys(selectValue);
      let optionsArray = selectKeys.map((selectkey, index) => {
        return {
          value: selectValue[selectkey],
          label: selectkey,
        };
      });
      return optionsArray;
    }
    return optionsArray;
  }

  render() {
    const { name, values, updateValues, path } = this.props;
    const { isUnibetGame, unibetGameTypes } = this.state;
    var selectValue = [];
    selectValue = selectValues[name];
    const { selectedOption } = this.state;
    // console.log("selectedOption--", selectedOption);
    let memo = memoizeOne(this.calculateOptionsArray, deepEqual);
    let optionsArray = memo(selectValue);
    let selectbox;
    if (path[2] === "team-a" && path[2] === "team-b") {
      selectbox = (
        <AsyncSelect
          fullWidth
          cacheOptions
          onFocus={this.loadOptions}
          loadOptions={this.loadOptions}
          defaultOptions
          type="date"
          value={selectedOption}
          name={name}
          onChange={this.handleChange}
          label={name}
        ></AsyncSelect>
      );
    } else if (isUnibetGame && path[2] === "type") {
      selectbox = (
        <AsyncSelect
          fullWidth
          cacheOptions
          defaultOptions={unibetGameTypes}
          type="date"
          value={selectedOption}
          name={name}
          onChange={this.handleChange}
          label={name}
        ></AsyncSelect>
      );
    } else if (!isUnibetGame) {
      selectbox = (
        <AsyncSelect
          fullWidth
          cacheOptions
          loadOptions={
            isUnibetGame && path[2] == "type"
              ? unibetGameTypes
              : this.loadOptions
          }
          defaultOptions
          type="date"
          value={selectedOption}
          name={name}
          onChange={this.handleChange}
          label={name}
        ></AsyncSelect>
      );
    }

    return (
      <React.Fragment>
        <InputLabel htmlFor={name}>{name}</InputLabel>
        {selectbox}
      </React.Fragment>
    );
  }
}
