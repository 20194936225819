import React, { Component } from 'react';
import dayjs from 'dayjs'
import TextField from '@material-ui/core/TextField';
import validateText from "./validateText";
import swal from 'sweetalert';

export default class DateInput extends Component {
    static defaultState = () => '';

    collect() {
        let date = validateText(this.props.values);
        if (date == null) {
            return date;
        }
        date = date.replace(/-/g, "");
        console.log(date);
        var dateObj = dayjs(date).format("YYYYMMDD");
        var todaysDate = dayjs();
        var diffDays = todaysDate.diff(dateObj, 'days')
        console.log(diffDays);
        if (diffDays > 0) {
            swal("Info Panel!", this.props.name + " More than Current Date", "info");
        }
        return date;
    }

    render() {
        const { name, values, updateValues, path } = this.props;
        let defaultValue = "";
        if (typeof values !== "undefined" && values !== null && values.length > 1 && !values.includes("-")) {
            defaultValue = values.slice(0, 4) + "-" + values.slice(4, 6) + "-" + values.slice(6);
            return <TextField
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                type="date"
                defaultValue={defaultValue}
                value={defaultValue}
                onChange={e => updateValues(path, e.target.value)}
                label={name}
            />
        }
        else {
            return <TextField
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                type="date"
                defaultValue={defaultValue}
                value={values}
                onChange={e => updateValues(path, e.target.value)}
                label={name}
            />
        }
    }
}
