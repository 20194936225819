import React, { Component } from 'react';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button/Button';
import {withStyles} from '@material-ui/core';
import styles from '../ui/theme';

const makeCreateUrl = id => 'https://cms.schibsted.media/workbench/' + id + ';newsroom=ab';

class CmsLink extends Component {
    state = {
        articleId: '',
    };

    openCreate() {
        const {articleId} = this.state;
        const url = makeCreateUrl(articleId);
        window.open(url,'_blank');
    }

    extractId = () => {
        navigator.clipboard.readText().then(text => {
            const res = text.match(/\/a\/([a-zA-Z0-9]+)/g);
            if (res) {
                const articleId = res[0].replace('/a/', '');
                // console.log(articleId);
                if (articleId) {
                    this.setState({articleId});
                }
            }
            // console.log(res);
        });
    };

    render() {
        const { articleId } = this.state;
        const { classes } = this.props;

        return [<InputBase
            key="1"
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            onClick={ this.extractId }
            placeholder="Article id"
            value={ articleId }
            onChange={ e => this.setState({articleId: e.target.value}) }
        />,
        <Button
            key="2"
            disabled={ articleId.trim() === '' }
            className={ classes.button }
            variant="contained"
            color="secondary"
            onClick={ () => this.openCreate() }
        >
            Edit
        </Button>];
    }
}

export default withStyles(styles)(CmsLink);
