import React, { Component } from 'react';
export default class NoteComp extends Component {

    static defaultState = () => '';
    collect() {
        return ;
    }

    render() {
        const { name, values, updateValues, path } = this.props;
        return (
            <>
                <p style={{color:'red'}}>{name}</p>
            </>
        )
    }
}
