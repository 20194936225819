const selectvalues = {
  template: { Loading: "Loading" },
  templateGamesCount: {},
  tags: [],
  expertname: { Loading: "Loading" },
  league: [],
  publish_article_title: { 0: "No", 1: "Yes" },
  publish_article_image_startpage_position: {
    0: "No",
    1: "Above",
    2: "Left",
    3: "Right",
  },
  publish_article_image_startpage: { 0: "No", 1: "Yes" },
  publish_article_image_detailpage: { 0: "No", 1: "Yes" },
  staging_article: { 0: "Production Live", 1: "Staging Test" },
  publish_article_sticky: { 0: "No", 1: "Yes" },
  publish_article_lead: { 0: "No", 1: "Yes" },
  publish_article_text: { 0: "No", 1: "Yes" },
  publish_article_image: { 0: "No", 1: "Yes" },
  sport: {
    1: "football",
    2: "ice-hockey",
    3: "innebandy",
    4: "tennis",
    5: "basket",
    6: "bandy",
    7: "handboll",
    8: "vintersport",
    9: "esports",
    10: "speedway",
    11: "golf",
    12: "cykel",
    13: "friidrott",
  },
  type: { 1: "1X2" },
  "team-a-grade": { "": 0, "+": 1, "++": 2, "+++": 3, "++++": 4, "+++++": 5 },
  "team-b-grade": { "": 0, "+": 1, "++": 2, "+++": 3, "++++": 4, "+++++": 5 },
  "team-a": {},
  "team-b": {},
};
export default selectvalues;
