import React, { Component } from "react";
import FormTree from "./core/FormTree";
import ArrayLayout from "./ui/ArrayLayout";
import CollectionLayout from "./ui/CollectionLayout";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography/Typography";
import CmsLink from "./util/CmsLink";
import _ from "lodash";
import styles from "./ui/theme";
import swal from "sweetalert";
import ReactModal from "react-modal";
import Clipboard from "react-clipboard.js";
// Config
import types from "./config/types";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import constants from "./util/constants";
import CustomGameModal from "./ui/CustomGameModal";
const axios = require("axios");

class App extends Component {
  state = {
    values: {},
    inputText: "",
    showModal: false,
    allowedArticles: [],
    showExpertModel: false,
    articleID: "",
    templateName: "Stryktipset",
    exportSuggestionFile: "",
    showGameModel: false,
  };

  componentDidMount() {
    this.fetchAllowedArticles(this.state.templateName);
  }

  fetchAllowedArticles = (name) => {
    let me = this;
    axios
      .get(`${constants.AB_URL}v1/expert_suggestions/allowed_articles`, {
        params: { product_name: name },
      })
      .then(function(response) {
        let data = response.data;
        me.setState({ allowedArticles: data.articleIds });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  handleOpenModal = () => {
    this.setState({ showModal: true, showExpertModel: false });
  };

  handleOpenExpertModal = () => {
    this.setState({ showExpertModel: true, showModal: false });
  };

  handleGameModal = () => {
    this.setState({ showGameModel: true });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      showExpertModel: false,
      showGameModel: false,
    });
  };

  handleChange = (event) => {
    this.setState({ articleID: event });
  };

  handleTemplateNameChange = (name) => {
    this.setState({ templateName: name, articleID: "" });
    if (name !== this.state.templateName) this.fetchAllowedArticles(name);
  };

  generate() {
    const rawValues = this.mainRef.collect();
    const values = _(rawValues)
      .omitBy(_.isNull)
      .value();
      debugger
    if (
      values["template"] === "Unibet - Games" &&
      _.isEmpty(values["template_alias"])
    ) {
      let msg = "<div> Template Alias is mandatory for Unibet Games</div><br/>";
      var div = document.createElement("div");
      div.innerHTML = msg.trim();
      swal({
        title: "Warning!",
        content: {
          element: div,
        },
        type: "info",
      });
    } else {
      let games = values.games;
      games &&
        games.map((obj, index) => {
          if (_.endsWith(obj.league, "-manual"))
            obj.league = obj.league.slice(0, -7);
          if (_.endsWith(obj["team-a"], "-manual"))
            obj["team-a"] = obj["team-a"].slice(0, -7);
          if (_.endsWith(obj["team-a"], "-manual"))
            obj["team-a"] = obj["team-a"].slice(0, -7);
          return obj;
        });
      values.games = games;
      this.setState({ values });
      const str = JSON.stringify({ seoStructuredData: values });
      fetch(`${constants.AB_URL}v1/events/validate_json`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then(function(response) {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wwrong." + response);
        })
        .then(function(data) {
          let msg = null;
          let dataKeys = Object.keys(data.errors);
          for (var key in dataKeys) {
            msg =
              "<div>Game " +
              (key + 1) +
              " " +
              data.errors[parseInt(key + 1)] +
              "</div><br/>";
          }
          if (msg !== null) {
            var div = document.createElement("div");
            div.innerHTML = msg.trim();
            swal({
              title: "Info Panel!",
              content: {
                element: div,
              },
              type: "info",
            });
          }
        });
      navigator.clipboard.writeText(str);
    }
  }

  onFileUploadChangeHandler = (event) => {
    this.setState({ exportSuggestionFile: event.target.files[0] });
  };

  uploadSuggestions(articleID) {
    const data = new FormData();
    data.append("file", this.state.exportSuggestionFile);
    data.append("articleid", articleID);
    axios
      .post(`${constants.AB_URL}v1/upload_suggestions`, data, {})
      .then(function(response) {
        let data = response.data;
        let msg = null;
        if (data.hasOwnProperty("success"))
          msg =
            "<div style='color: lightgreen'>" + data.success + "</div><br/>";
        if (data.hasOwnProperty("error"))
          msg = "<div style='color:red;'>" + data.error + "</div><br/>";
        if (msg !== null) {
          var div = document.createElement("div");
          div.innerHTML = msg.trim();
          swal({
            title: "Info Panel!",
            content: {
              element: div,
            },
            type: "info",
          });
        }
      });
  }

  submitGame() {
    const rawValues = this.mainRef.collect();
    const values = _(rawValues)
      .omitBy(_.isNull)
      .value();
    //  var zoneDiff = new Date().getTimezoneOffset();
    // values.zoneDiff = zoneDiff;

    console.log("game submit", values);
    axios
      .post(`${constants.AB_URL}v1/manual_event`, values)
      .then(function(response) {
        let data = response;
        let msg = null;
        console.log("response", data);
        if (data.hasOwnProperty("status") && data.status == 200) {
          console.log("came here");
          msg =
            "<div style='color: green'>" +
            "Game added successfully" +
            "</div><br/>";
        }
        if (data.hasOwnProperty("error"))
          msg =
            "<div style='color:red;'>" +
            "All Fields are mandatory" +
            "</div><br/>";
        if (msg !== null) {
          var div = document.createElement("div");
          div.innerHTML = msg.trim();
          swal({
            title: "Info Panel!",
            content: {
              element: div,
            },
            type: "info",
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  updateArticle(articleID, activity) {
    const rawValues = this.mainRef.collect();
    const values = _(rawValues)
      .omitBy(_.isNull)
      .value();
    const str = JSON.stringify({ seoStructuredData: values });
    fetch(`${constants.AB_URL}v1/articles/` + articleID + "/sync_article", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        let msg = null;
        if (data.hasOwnProperty("success"))
          msg = "<div>" + data.success + "</div><br/>";
        if (data.hasOwnProperty("error"))
          msg = "<div>" + data.error + "</div><br/>";
        if (msg !== null) {
          var div = document.createElement("div");
          div.innerHTML = msg.trim();
          swal({
            title: "Info Panel!",
            content: {
              element: div,
            },
            type: "info",
          });
        }
      });
    navigator.clipboard.writeText(str);
  }

  clear() {
    this.mainRef.clear();
    this.setState({
      values: {},
      inputText: "",
    });
  }

  load() {
    const { inputText } = this.state;
    try {
      let obj = JSON.parse(inputText);
      if (obj.seoStructuredData) {
        obj = obj.seoStructuredData;
      }
      this.mainRef.setValue(obj);
      this.setState({ inputText: "" });
    } catch (e) {}
  }

  render() {
    const { classes } = this.props;
    const { values, inputText } = this.state;
    return (
      <div>
        <CssBaseline />
        <AppBar color="primary" position="fixed" className={classes.appBar}>
          <div className={classes.flexRow}>
            <Typography
              className={classes.appBarTitle}
              variant="h6"
              color="inherit"
              noWrap
            >
              Tlāloc
            </Typography>
            {this.state.showModal && (
              <ReactModal
                isOpen={this.state.showModal}
                contentLabel="update/Delete"
              >
                <Button
                  variant="contained"
                  style={{ float: "right", margin: "10px" }}
                  color="secondary"
                  className={classes.button}
                  onClick={this.handleCloseModal}
                >
                  Close Modal
                </Button>
                <TextField
                  fullWidth
                  multiline
                  value={this.state.articleID}
                  onChange={(e) => {
                    this.handleChange(e.target.value);
                  }}
                  label="Article ID"
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  style={{ float: "left" }}
                  color="secondary"
                  className={classes.button}
                  onClick={() =>
                    this.updateArticle(this.state.articleID, "update")
                  }
                >
                  Update Article
                </Button>
              </ReactModal>
            )}
            {this.state.showGameModel && (
              <ReactModal
                isOpen={this.state.showGameModel}
                contentLabel="update/Delete"
              >
                <CustomGameModal handleCloseModal={this.handleCloseModal} />
                <br />
              </ReactModal>
            )}
            {this.state.showExpertModel && (
              <ReactModal
                isOpen={this.state.showExpertModel}
                contentLabel="Upload export suggestions"
              >
                <Button
                  variant="contained"
                  style={{ float: "right", margin: "10px" }}
                  color="secondary"
                  className={classes.button}
                  onClick={this.handleCloseModal}
                >
                  Close Modal
                </Button>
                <br />
                <br />
                <br />
                <br />
                <InputLabel htmlFor="templateId">Template</InputLabel>
                <Select
                  value={this.state.templateName}
                  onChange={(e) => {
                    this.handleTemplateNameChange(e.target.value);
                  }}
                  name="templateName"
                  inputProps={{ name: "templateName", id: "templateName" }}
                  fullWidth
                >
                  <MenuItem value="Stryktipset">Stryktipset</MenuItem>
                </Select>
                <br />
                <br />
                <br />
                <InputLabel htmlFor="articleID">Article id</InputLabel>
                <Select
                  value={this.state.articleID}
                  onChange={(e) => {
                    this.handleChange(e.target.value);
                  }}
                  name="articleId"
                  inputProps={{ name: "articleID", id: "articleID" }}
                  fullWidth
                >
                  {this.state.allowedArticles.map((articleid) => (
                    <MenuItem value={articleid}>{articleid}</MenuItem>
                  ))}
                </Select>
                <br />
                <br />
                <br />
                <div>
                  <input
                    className={classes.input}
                    id="raised-button-file"
                    type="file"
                    onChange={(e) => this.onFileUploadChangeHandler(e)}
                  />
                </div>{" "}
                <br />
                <br />
                <Button
                  variant="contained"
                  style={{ float: "left" }}
                  color="secondary"
                  className={classes.button}
                  onClick={() => this.uploadSuggestions(this.state.articleID)}
                >
                  Upload Suggestions
                </Button>
              </ReactModal>
            )}
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => this.handleGameModal()}
            >
              Add Custom Game
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => this.handleOpenExpertModal()}
            >
              Expert suggestions
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => this.handleOpenModal()}
            >
              Sync Article
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => this.generate()}
            >
              Generate
            </Button>

            <Button
              disabled={inputText.trim() === ""}
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => this.load()}
            >
              Load
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => this.clear()}
            >
              Clear
            </Button>

            <CmsLink />
          </div>
        </AppBar>
        <Grid container alignItems="flex-start">
          <Grid item xs={8}>
            <main className={classes.main}>
              <FormTree
                arrayLayout={ArrayLayout}
                collectionLayout={CollectionLayout}
                name={"Expert Advise"}
                type={"ExpertAdvise"}
                types={types}
                ref={(r) => (this.mainRef = r)}
                onSave={() => this.generate()}
              />
            </main>
          </Grid>
          <Grid item xs={4}>
            <aside className={classes.aside}>
              <Paper className={classes.paper}>
                <Clipboard
                  className={classes.copyToClipBoardButton}
                  data-clipboard-text={JSON.stringify(values)}
                >
                  copy to clipboard
                </Clipboard>
                <TextField
                  fullWidth
                  multiline
                  label="Existing json"
                  value={inputText}
                  onChange={(e) => this.setState({ inputText: e.target.value })}
                />

                <pre className={classes.pre}>
                  <code className={classes.code}>
                    {JSON.stringify(values, null, 4)}
                  </code>
                </pre>
                <pre className={classes.pre}>
                  <code className={classes.code}>{JSON.stringify(values)}</code>
                </pre>
              </Paper>
            </aside>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(App);
