import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import styles from './theme';

class CollectionLayout extends Component {
    render() {
        const {name, children, classes} = this.props;

        return <Paper className={ classes.paper }>
            <Typography variant="h5">{name}</Typography>
            {children}
        </Paper>
    }
}

export default withStyles(styles)(CollectionLayout);
