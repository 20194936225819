import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import Axios from "axios";
import constants from "../util/constants";
import AsyncCreatableSelect from "react-select/async-creatable";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { FormControl, InputLabel, MenuItem, Button } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import _ from "lodash";
import swal from "sweetalert";
export default function CustomGameModal(props) {
  const [date, setdate] = useState();
  const [league, setleague] = useState();
  const [team1, setteam1] = useState();
  const [team2, setteam2] = useState();
  const [sport, setsport] = useState();

  const getOptions = inputValue => {
    const URL = `${constants.AB_URL}v1/search_league?sport=${sport}&date=${date}&league_name=${inputValue}`;

    let x = Axios.get(URL)
      .then(resp => {
        return resp.data;
      })
      .then(data => {
        let options = [];
        options = data.map((val, index) => {
          let x = { label: val, value: val };
          return x;
        });
        return options.filter(i =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      })
      .catch(err => console.log(err));
    return x;
  };

  const sportOptions = inputValue => {
    const URL = `${constants.AB_URL}v1/search_team?sport=${sport}&team_name=${inputValue}`;
    let x = Axios.get(URL)
      .then(resp => {
        return resp.data;
      })
      .then(data => {
        let options = [];
        _.forEach(data, team => {
          let x = {
            label: `Name :${team.name} || Gender :${team.gender}`,
            value: { id: team.schibsted_participantid, name: team.name }
          };
          options.push(x);
        });
        // console.log(options);
        return options.filter(i =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
      })
      .catch(err => console.log(err));
    return x;
  };

  const submitTeam = () => {
    let requestObject = {};
    requestObject.sport = sport;
    if (_.isObject(team1)) {
      requestObject["team-a"] = team1.name;
      requestObject["team-b"] = team2.name;
      requestObject["team-a-id"] = team1.id;
      requestObject["team-b-id"] = team2.id;
    }else{
      requestObject["team-a"] = team1;
      requestObject["team-b"] = team2;
    } 
    requestObject.league = league;
    requestObject.date = date;
    Axios.post(`${constants.AB_URL}v1/manual_event`, requestObject)
      .then(function(response) {
        let data = response;
        let msg = null;
        if (data.hasOwnProperty("status") && data.status == 200) {
          props.handleCloseModal();
          msg =
            "<div style='color: green'>" +
            "Game added successfully" +
            "</div><br/>";
        }
        if (data.hasOwnProperty("error"))
          msg =
            "<div style='color:red;'>" +
            "All Fields are mandatory" +
            "</div><br/>";
        if (msg !== null) {
          var div = document.createElement("div");
          div.innerHTML = msg.trim();
          swal({
            title: "Info Panel!",
            content: {
              element: div
            },
            type: "info"
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <FormControl className={""}>
          {/* <InputLabel htmlFor="my-input">Date</InputLabel> */}
          <TextField
            id="date"
            label="Start Date"
            type="date"
            InputLabelProps={{
              shrink: true
            }}
            onChange={event => {
              setdate(event.target.value);
            }}
          />
        </FormControl>
      </div>
      <br />
      <div style={{ width: "100% !important" }}>
        <InputLabel id="sport">Sport</InputLabel>
        <FormControl>
          <Select
            labelId="sport"
            id="sport"
            value={sport}
            onChange={event => {
              setsport(event.target.value);
            }}
          >
            <MenuItem value={"football"}>Football</MenuItem>
            <MenuItem value={"ice-hockey"}>Ice Hockey</MenuItem>
            <MenuItem value={"innebandy"}>Innebandy</MenuItem>
            <MenuItem value={"tennis"}>Tennis</MenuItem>
            <MenuItem value={"basket"}>Basket</MenuItem>
            <MenuItem value={"bandy"}>Bandy</MenuItem>
            <MenuItem value={"handboll"}>Handboll</MenuItem>
            <MenuItem value={"vintersport"}>Vintersport</MenuItem>
            <MenuItem value={"esports"}>Esports</MenuItem>
            <MenuItem value={"speedway"}>Speedway</MenuItem>
            <MenuItem value={"golf"}>Golf</MenuItem>
            <MenuItem value={"cykel"}>Cykel</MenuItem>
            <MenuItem value={"friidrott"}>Friidrott</MenuItem>
          </Select>
        </FormControl>
      </div>
      <br />
      <div>
        <InputLabel id="demo-simple-select-label">League</InputLabel>
        <AsyncCreatableSelect
          cacheOptions
          loadOptions={getOptions}
          onChange={data => {
            setleague(data.value);
          }}
          // value={league}
        />
      </div>
      <br />
      <div>
        <InputLabel id="demo-simple-select-label">Team one</InputLabel>
        <AsyncCreatableSelect
          cacheOptions
          loadOptions={sportOptions}
          onChange={data => {
            setteam1(data.value);
          }}
          // value={team1}
        />
      </div>
      <br />
      <div>
        <InputLabel id="demo-simple-select-label">Team 2</InputLabel>
        <AsyncCreatableSelect
          cacheOptions
          loadOptions={sportOptions}
          onChange={data => {
            setteam2(data.value);
          }}
          // value={team2}
        />
      </div>
      <br />
      <Button variant="contained" onClick={submitTeam}>
        Save
      </Button>
      <Button variant="contained" onClick={props.handleCloseModal}>
        Cancel
      </Button>
    </>
  );
}
