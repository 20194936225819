import React, { Component } from 'react';
export default class SubHeaderComp extends Component {

    static defaultState = () => '';
    collect() {
        return ;
    }

    render() {
        const { name, values, updateValues, path } = this.props;
        return (
            <>
                <h3>{name}</h3>
            </>
        )
    }
}