import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import styles from './theme';

class ArrayLayout extends Component {
    render() {
        const {name, children, onAdd, onRemove, classes} = this.props;

        if(children.length === 13) {
            return <Paper className={ classes.smallPaper } >
                    <p style={{color:'red'}}>Note*: The leagues and team names highlighted in red color are manually added games</p>
                    <Typography variant="h6">{name}</Typography>
                    <Typography variant="h6">{name} Count : ({children.length})</Typography> 
                    {children}
                </Paper>
        } else {
            return  <Paper className={ classes.smallPaper } >
            <p style={{color:'red'}}>Note*: The leagues and team names highlighted in red color are manually added games</p>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="h6">{name} Count : ({children.length})</Typography>       
            <Button	
            className={ classes.button }
            variant="contained"	
            color="primary"	
            onClick={ onAdd }	
            >+</Button>	
            
            <Button	
            className={ classes.button }	
            variant="contained"	
            color="primary"	
            onClick={ onRemove }	
            >-</Button>	
            ({children.length})   
            {children}
        </Paper>
        }
        
    }
}

export default withStyles(styles)(ArrayLayout);
