import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import validateText from "./validateText";
import selectValues from "../config/selectvalues";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import types from "../config/types";
import constants from "../util/constants";
import _ from "lodash";
const axios = require("axios");

export default class SelectboxComp extends Component {
  static defaultState = () => "";
  state = {
    templateEntries: null,
  };

  collect() {
    return validateText(this.props.values);
  }

  componentWillReceiveProps(newprops) {
    // console.log("componentWillReceiveProps(newprops) ", newprops);
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
      const { name, values, updateValues, path, unibetData } = newprops;
      // console.log("componentWillReceiveProps", name, values);
      if (name === "sport" && !_.isEmpty(unibetData)) {
        let selectSportValue = {
          1: "football",
          2: "ice-hockey",
          // 3: "innebandy",
          // 4: "tennis",
          // 5: "basket",
          // 6: "bandy",
          // 7: "handboll",
          // 8: "vintersport",
          // 9: "esports",
          // 10: "speedway",
          // 11: "golf",
          // 12: "cykel",
          // 13: "friidrott",
        };
        // let selectKeys = Object.keys(selectSportValue);
        this.setState({
          templateEntries: selectSportValue,
        });
        let selectKeys = Object.keys(selectSportValue);
        // if (typeof values !== "undefined" || values.length < 2) {
        //   setTimeout(() => {
        //     console.log("values------------->", selectKeys[values], values);
        //     updateValues(path, selectKeys[values]);
        //   }, 1000);
        // }
      }
      if (name === "league" && !_.isEmpty(unibetData)) {
        // this.fetchLeague(true, values, path);
        let leagues = {};
        unibetData.Events.map((event, index) => {
          leagues[event.Group] = event.Group;
        });
        this.setState({
          templateEntries: leagues,
        });
        this.setUnibetGameLeague(values, path, updateValues);
      }
      if (name === "team-a" && !_.isEmpty(unibetData)) {
        let homeTeams = {};
        unibetData.Events.map((event, index) => {
          homeTeams[event.HomeName] = event.HomeName;
        });
        this.setState({
          templateEntries: homeTeams,
        });
        // this.fetchTeams(true, values, path);
      }
      if (name === "team-b" && !_.isEmpty(unibetData)) {
        let awayTeams = {};
        unibetData.Events.map((event, index) => {
          awayTeams[event.AwayName] = event.AwayName;
        });
        this.setState({
          templateEntries: awayTeams,
        });
        // this.fetchTeamB(true, values, path);
      }
    }
  }

  componentWillMount() {
    const { name, values, updateValues, path } = this.props;
    var selectValue = [];
    selectValue = selectValues[name];
    let selectKeys = Object.keys(selectValue);
    if (typeof values === "undefined" || values.length < 2) {
      if (path[0] === "staging_article") {
        setTimeout(() => {
          updateValues(path, selectKeys[0]);
        }, 100);
      } else if (path[2] === "sport") {
        // debugger
        setTimeout(() => {
          updateValues(path, selectKeys[values-1]);
        }, 100);
      } else {
        setTimeout(() => {
          updateValues(path, selectKeys[values]);
        }, 100);
      }
    }

    if (path[0] === "template") {
      this.fetchtemplate();
    } else if (path[0] === "expertname") {
      this.fetchExpertNames();
    }
  }
  onFocus = (path, name, event) => {
    if (path.length === 3 && path[2] === "league") {
      this.fetchLeague();
    } else if (path.length === 3 && path[2] === "team-a") {
      this.fetchTeams();
    } else if (path.length === 3 && path[2] === "team-b") {
      this.fetchTeamB();
    } else if (path.length === 3 && path[2] === "sport") {
      this.fetchSports();
    } else if (path[0] === "sport") {
      this.sportOptions();
    }
  };

  fetchExpertNames = () => {
    var expertEntries = {};
    const { name, values, updateValues, path } = this.props;
    var me = this;
    axios.get(`${constants.AB_URL}v1/experts`, {}).then(function(response) {
      var experts = response.data;
      for (var i = 0; i < experts.length; i++) {
        expertEntries[experts[i]] = experts[i];
      }
      selectValues["expertname"] = expertEntries;
      me.setState({
        templateEntries: expertEntries,
      });
      let selectKeys = Object.keys(expertEntries);
      if (values.length < 2) {
        setTimeout(() => {
          updateValues(path, selectKeys[0]);
        }, 50);
      }
    });
    return expertEntries;
  };

  fetchTeamB = (updateGameObject, teamName) => {
    const { buildValueTree, getGame, updateValues, path } = this.props;
    const game = getGame();
    var gameStartDate = game["game-start-date"];
    var league = game["league"];
    var teamA = game["team-a"];
    var gameSport = game.sport;
    var me = this;
    var isManual = false;
    if (league && league.endsWith("-manual")) {
      league = league.slice(0, -7);
      isManual = true;
      teamA = teamA.slice(0, -7);
    }
    var d = new Date();
    var zoneDiff = d.getTimezoneOffset();
    let param = {
      date: gameStartDate,
      sport: gameSport,
      league: league,
      team_name: teamA,
      zoneDiff: zoneDiff,
      manual: isManual,
    };
    if (!isManual) delete param["manual"];
    if (typeof teamA !== "undefined") {
      axios
        .get(`${constants.AB_URL}v1/opposite_team`, { params: param })
        .then(function(response) {
          var teamsb = response.data;
          var teambObj = {};
          teambObj[teamsb.team_name] = teamsb.team_name;
          me.setState({
            templateEntries: teambObj,
          });
          let team_a_participant_id = teamsb.team_a_participant_id;
          let team_b_participant_id = teamsb.team_b_participant_id;
          let eventid = teamsb.eventid;
          setTimeout(() => {
            updateValues(path, teamsb.team_name);
          }, 100);
          setTimeout(() => {
            let teamAPath = path;
            teamAPath[2] = "team_a_participant_id";
            updateValues(path, team_a_participant_id);
          }, 300);

          setTimeout(() => {
            let teamAPath = path;
            teamAPath[2] = "team_b_participant_id";
            updateValues(path, team_b_participant_id);
          }, 500);
          setTimeout(() => {
            let teamAPath = path;
            teamAPath[2] = "eventid";
            updateValues(path, eventid);
          }, 700);
        });
    }
  };

  sportOptions = () => {
    this.setState({
      templateEntries: constants.sportMap,
    });
  };

  fetchSports = () => {
    const { buildValueTree, getGame } = this.props;
    const game = getGame();
    var gameStartDate = game["game-start-date"];
    var gameSport = game.sport;
    var d = new Date();
    var zoneDiff = d.getTimezoneOffset();
    var me = this;
    axios
      .get(`${constants.AB_URL}v1/sports`, {
        params: { date: gameStartDate, zoneDiff: zoneDiff },
      })
      .then(function(response) {
        var sports = response.data;
        var sportsList = {};
        for (var i = 0; i < sports.length; i++) {
          sportsList[sports[i][1]] = sports[i][0];
        }
        me.setState({
          templateEntries: sportsList,
        });
      });
  };

  setUnibetGameLeague = (leagueName, path, updateValues) => {
    setTimeout(() => {
      updateValues(path, leagueName);
    }, 100);
    // me.setState({
    //   templateEntries: leagues,
    // });
  };

  fetchLeague = (updateGameObject, leagueName, path) => {
    const { buildValueTree, getGame, updateValues } = this.props;
    // console.log(updateGameObject, leagueName, path);
    const game = getGame();
    var gameSport = game.sport;
    var gameStartDate = game["game-start-date"];
    if (
      typeof gameSport !== "undefined" &&
      typeof gameStartDate !== "undefined"
    ) {
      var d = new Date();
      var zoneDiff = d.getTimezoneOffset();
      var me = this;
      axios
        .get(`${constants.AB_URL}v1/leagues`, {
          params: { date: gameStartDate, zoneDiff: zoneDiff, sport: gameSport },
        })
        .then(function(response) {
          var leagues = response.data;
          // var leaguesList = {};
          // for (var i = 0; i < leagues.length; i++) {
          //     leaguesList[leagues[i]] = leagues[i];
          // }
          if (updateGameObject) {
            let league = leagues.dynamic.find((x) => x.includes(leagueName));
            setTimeout(() => {
              updateValues(path, league);
            }, 100);
          }
          me.setState({
            templateEntries: leagues,
          });
        });
    }
  };

  fetchTeams = (updateGameObject, teamName, path) => {
    const { buildValueTree, getGame, updateValues } = this.props;
    // console.log(updateGameObject, teamName, path);
    const game = getGame();
    var gameSport = game.sport;
    var gameStartDate = game["game-start-date"];
    var gameLeague = game["league"];
    var d = new Date();
    var zoneDiff = d.getTimezoneOffset();
    var me = this;
    var isManual = false;
    if (gameLeague && gameLeague.endsWith("-manual")) {
      gameLeague = gameLeague.slice(0, -7);
      isManual = true;
    }
    let param = {
      date: gameStartDate,
      zoneDiff: zoneDiff,
      sport: gameSport,
      league: gameLeague,
      manual: isManual,
    };
    if (!isManual) delete param["manual"];
    axios
      .get(`${constants.AB_URL}v1/teams`, { params: param })
      .then(function(response) {
        var gameTeams = response.data;
        // var gameTeamsArray = {};
        // for (var i = 0; i < gameTeams.length; i++) {
        //     gameTeamsArray[gameTeams[i]] = gameTeams[i];
        // }
        me.setState({
          templateEntries: gameTeams,
        });
        if (updateGameObject && !_.isEmpty(gameLeague)) {
          let team = [...gameTeams.dynamic, ...gameTeams.manual].find((x) =>
            x.includes(teamName)
          );
          if (!_.isEmpty(team)) {
            setTimeout(() => {
              updateValues(path, team);
            }, 100);
          }
        }
        // return gameTeamsArray;
      });
  };

  fetchtemplate = () => {
    var templateEntries = {};
    const { name, values, updateValues, path } = this.props;
    var me = this;
    axios.get(`${constants.AB_URL}v1/templates`, {}).then(function(response) {
      var template = response.data;
      template.push("Unibet - Games");
      for (var i = 0; i < template.length; i++) {
        templateEntries[template[i]] = template[i];
      }
      selectValues["template"] = templateEntries;
      me.setState({
        templateEntries: templateEntries,
      });
      let selectKeys = Object.keys(templateEntries);
      if (values.length < 2) {
        setTimeout(() => {
          updateValues(path, selectKeys[0]);
        }, 50);
      }
    });
    return templateEntries;
  };

  checkForGames(path, selectedValue, value, event) {
    const { buildValueTree, addLast, values, name, updateValues } = this.props;
    var selectValue = [];
    selectValue = selectValues["templateGamesCount"];
    var selectCount = selectValue[selectedValue];
    if (typeof selectCount !== "undefined") {
      var valuePushed = [];
      var newDataPush = [];
      for (var i = 0; i < selectCount; i++) {
        const value = buildValueTree("Game", types);
        const newData = addLast(valuePushed, value);
        valuePushed.push(value);
        updateValues(["games"], newData);
        setTimeout(() => {
          updateValues(path, selectedValue);
        }, 500);
      }
      if (selectCount === 0) {
        updateValues(["games"], []);
        setTimeout(() => {
          updateValues(path, selectedValue);
        }, 500);
      }
    } else {
      updateValues(path, selectedValue);
    }
    if (selectedValue === "Stryktipset") {
      axios
        .get(`${constants.AB_URL}v1/events/template_games`, {
          params: { template: "stryktipset" },
        })
        .then(function(response) {
          var data = response.data;
          const value = buildValueTree("Game", types);
          var valuePushed = [];
          setTimeout(() => {
            for (var i = 0; i < data.games.length; i++) {
              const newData = addLast(valuePushed, data.games[i]);
              valuePushed.push(data.games[i]);
              updateValues(["games"], newData);
              setTimeout(() => {
                updateValues(path, selectedValue);
              }, 1000);
            }
          }, 100);
        });
    }
  }

  render() {
    const { name, values, updateValues, path } = this.props;
    var selectValue;
    if (this.state.templateEntries === null) {
      selectValue = selectValues[name];
    } else {
      selectValue = this.state.templateEntries;
    }
    let options = [];
    if (_.has(selectValue, "dynamic")) {
      // console.log("templateEntries", this.state.templateEntries.dynamic);
      selectValue.dynamic.map((selectVal, index) => {
        options.push(
          <MenuItem key={index} value={selectVal}>
            {selectVal}
          </MenuItem>
        );
      });
    }
    if (_.has(selectValue, "manual") && !_.isEmpty(selectValue.manual)) {
      selectValue.manual.map((selectVal, index) => {
        options.push(
          <MenuItem
            style={{ color: "red" }}
            key={index}
            value={`${selectVal}-manual`}
          >
            {selectVal + " " + " (Manual)"}
          </MenuItem>
        );
      });
    }
    if (!_.has(selectValue, "dynamic") && !_.has(selectValue, "manual")) {
      // console.log("templateEntries========", selectValue);
      let selectKeys = selectValue && Object.keys(selectValue);
      options = selectKeys.map((selectVal, index) => {
        return (
          <MenuItem key={index} value={selectVal}>
            {selectValue[selectVal]}
          </MenuItem>
        );
      });
    }
    let selectbox = (
      <Select
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={values}
        value={values}
        name={name}
        onFocus={(e) => {
          this.onFocus(path, name, e);
        }}
        onChange={(e) => {
          this.checkForGames(path, e.target.value, name);
        }}
        label={name}
      >
        {options}
      </Select>
    );

    return (
      <React.Fragment>
        <InputLabel htmlFor={name}>{name}</InputLabel>
        {selectbox}
      </React.Fragment>
    );
  }
}
