import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import validateText from "./validateText";

export default class TextInput extends Component {
  static defaultState = () => "";

  collect() {
    return validateText(this.props.values);
  }

  onClick = () => {
    const { path, values, updateValues } = this.props;

    navigator.clipboard.readText().then((text) => {
      if (values === "") {
        updateValues(path, text.trim());
      }
    });
  };

  shouldComponentUpdate(nextProps, x, y) {
    return this.props.values !== nextProps.values;
  }

  render() {
    const { name, values, updateValues, path, templateData, test } = this.props;
    if (
      (path.length === 3 &&
        (path[2] === "eventid" ||
          path[2] === "team_a_participant_id" ||
          path[2] === "team_b_participant_id")) ||
      path[2] === "unibet-eventid"
    ) {
      return (
        <TextField
          id="test"
          fullWidth
          multiline
          value={values}
          onClick={this.onClick}
          onChange={(e) => updateValues(path, e.target.value)}
          label={name}
          style={{ display: "none" }}
        />
      );
    } else {
      return (
        <TextField
          fullWidth
          multiline
          value={values}
          onClick={this.onClick}
          onChange={(e) => updateValues(path, e.target.value)}
          label={name}
        />
      );
    }
  }
}
