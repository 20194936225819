const constants = {
  AB_URL_TEST: "https://ab-liveodds.cortexcraft.com/api/",
  AB_URL: "https://ab-affiliate.dunderdog.se/api/",
  sportMap: {
    football: "football",
    "ice-hockey": "ice-hockey",
    innebandy: "innebandy",
    tennis: "tennis",
    basket: "basket",
    bandy: "bandy",
    handboll: "handboll",
    vintersport: "vintersport",
    esports: "esports",
    speedway: "speedway",
    golf: "golf",
    cykel: "cykel",
    friidrott: "friidrott",
  },
  UNIBET_PARTICIPANT_GAMES:["Head to Head","Player Occurrence Line"]
};
export default constants;
