import React, { Component } from 'react';
import Select from 'react-select';
import selectValues from "../config/selectvalues";
import memoizeOne from 'memoize-one';
import deepEqual from 'lodash/isEqual';
export default class MultiSelectBoxComp extends Component {

    static defaultState = () => '';
    state = {
        selectedOption: null,
        options: []
    }
    componentDidMount(){
        this.fetchTags();
    }

    collect() {
        const { values } = this.props;
        if (values === null) {
            return null;
        }
        return values;
    }
    handleChange = (selectedOption) => {
        const { path, updateValues } = this.props;
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
        var selectOptionsValue = [];
        for (var i = 0; i < selectedOption.length; i++) {
            selectOptionsValue.push(selectedOption[i].value);
        }
        updateValues(path, selectOptionsValue);
    }

    fetchTags =  () => {
        var tagsArray = [];
         fetch("https://ab-affiliate.dunderdog.se/api/v1/tags.json", {
            method: 'get'
        }).then( (response) => {
            return response.json();
        }).then( (tags) => {
            for (var i = 0; i< tags.length; i++) {
                let tagEntries = {};
                tagEntries["value"] = tags[i];
                tagEntries["label"] = tags[i];
                tagsArray.push(tagEntries);
            }
            this.setState({
                options: tagsArray,
                apicalled: true
            })
            selectValues["tags"] = tagsArray;
        })
        return tagsArray;
    }

    render() {
        const { name, values, updateValues, path } = this.props;
        var options = [];
        if(this.state.options.length > 0){
            options = selectValues[name];
        }
        else{
            options = this.state.options;
        }
        const { selectedOption } = this.state;
        return (
            <React.Fragment>
                <Select
                    isMulti
                    name="colors"
                    value={selectedOption}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={name}
                    onChange={this.handleChange}></Select>
            </React.Fragment>
        )
    }
}