import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import validateText from "./validateText";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import types from "../config/types";
import constants from "../util/constants";
import _ from "lodash";
import moment from "moment";
import swal from "sweetalert";
const axios = require("axios");
export default class UnibetDataFetch extends Component {
  static defaultState = () => "";

  collect() {
    return validateText(this.props.values);
  }

  componentWillMount() {
    const { name, values, updateValues, path } = this.props;
  }

  componentWillReceiveProps(newprops) {
    if (newprops !== this.props) {
      this.setState({
        ...this.state,
        ...newprops,
      });
    }
  }

  onClick = () => {
    const { path, values, updateValues } = this.props;

    navigator.clipboard.readText().then((text) => {
      if (values === "") {
        updateValues(path, text.trim());
      }
    });
  };

  shouldComponentUpdate(nextProps, x, y) {
    return this.props.values !== nextProps.values;
  }

  updateGameValues = (gamesArray) => {
    const { updateValues } = this.props;
    updateValues(["games"], gamesArray);
  };

  fetchUnibetData = () => {
    var templateEntries = {};
    const {
      name,
      values,
      updateValues,
      path,
      buildValueTree,
      updateUnibetState,
    } = this.props;
    // console.log("unibet values----------->", values);
    var me = this;

    const requestURL = `${constants.AB_URL}v1/valuebase_url_data?valuebase_url=${encodeURIComponent(values)}`
    axios
      .get(
        requestURL,
        {}
      )
      .then((response) => {
        // console.log("unibet response--->", response.data);
        const value = buildValueTree("Game", types);
        var valuePushed = [];
        let events = response.data.Events;
        let betOffersArray = response.data.BetOffers;
        if (_.size(events) > 1) {
          updateValues(["template"], "Unibet - Games");
        }
        betOffersArray.map((betoffer, index) => {
          let outcomes = betoffer.Outcomes;
          let eventId = betoffer.EventId;
          let event = _.find(events, ["Id", eventId]);
          const betWin = outcomes.find((x) => x.Selected == true);
          let criterion = betoffer.Criterion.Label;
          let BetOfferType = betoffer.BetOfferType.Name;
          let eventDate = moment(event.Start).format("YYYY-MM-DD");
          let game = {
            "betting-link": response.data.Url,
            "betting-partner": "unibet",
            eventid: "",
            "unibet-eventid": eventId,
            "game-comment": "",
            "game-start-date": eventDate,
            // league: event.Group,
            league: event.Group,
            sport: event.Sport == "FOOTBALL" ? "1" : "2",
            "team-a": event.HomeName,
            "team-a-comment": "",
            "team-a-grade": "",
            "team-b": event.AwayName,
            "team-b-comment": "",
            "team-b-grade": "",
            team_a_participant_id: "",
            team_b_participant_id: "",
            type: criterion,
            value: constants.UNIBET_PARTICIPANT_GAMES.includes(BetOfferType)
              ? betWin.Participant
              : betWin.Label,
            "betting-odds": betWin.OddsDecimalString,
            game: index + 1,
          };
          valuePushed.push(game);
        });
        setTimeout(() => {
          updateUnibetState(response.data);
          updateValues(["games"], valuePushed);
        }, 500);
        return valuePushed;
      })
      .then((resp) => {
        // console.log("uunibet----resp----->", resp);
        let gamesArray = resp.map((x, i) => {
          return _.pick(x, [
            "betting-link",
            "betting-odds",
            "betting-partner",
            "game",
            "game-start-date",
            "league",
            "sport",
            "team-a",
            "team-b",
            "type",
            "unibet-eventid",
            "value",
          ]);
        });
        let filteredGames = _.filter(gamesArray, (game) => {
          if (!_.isEmpty(game["team-a"] && !_.isEmpty(game["team-b"]))) {
            return game;
          }
        });
        if (_.size(filteredGames) > 0) {
          this.validateGames(resp, filteredGames, updateValues);
        } else {
          let successMsg =
            "<div>Data fetched and validated successfully</div><br/>";
          var div = document.createElement("div");
          div.innerHTML = successMsg.trim();
          swal({
            // title: "Info Panel!",
            content: {
              element: div,
            },
            type: "info",
          });
        }
      });
    return templateEntries;
  };

  validateGames = (betOffersArray, filteredGames, updateValues) => {
    // console.log("betOffersArray====================>", betOffersArray);
    let requestObj = { games: filteredGames };
    fetch(`${constants.AB_URL}v1/events/validate_json`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObj),
    })
      .then(function(response) {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wwrong." + response);
      })
      .then(function(data) {
        let msg = "";
        let dataKeys = Object.keys(data.errors);
        let errors = data.errors;
        debugger;
        for (var key in errors) {
          if (errors.hasOwnProperty(key)) {
            console.log(key + " -> " + errors[key]);
            msg = msg + "<div>Game " + key + " " + errors[key] + "</div><br/>";
          }
        }
        console.log("msg==============================", msg);
        // for (var key in dataKeys) {
        // msg = msg + "<div>Game " + (key + 1) + " " + data.errors[parseInt(key + 1)] + "</div><br/>";
        // }
        if (msg !== "") {
          var div = document.createElement("div");
          div.innerHTML = msg.trim();
          swal({
            // title: "Info Panel!",
            content: {
              element: div,
            },
            type: "info",
          });
        }
        // setTimeout(() => {
        //   updateValues(["games"], betOffersArray);
        // }, 100);
      });
  };

  render() {
    // console.log("unibet===============", this.props);
    const { name, values, updateValues, path } = this.props;
    return (
      <div>
        <TextField
          fullWidth
          multiline
          value={values}
          onClick={this.onClick}
          onChange={(e) => updateValues(path, e.target.value)}
          label={name}
        />
        <Button
          variant="contained"
          style={{ float: "right", margin: "10px" }}
          color="secondary"
          onClick={this.fetchUnibetData}
          //   className={classes.button}
        >
          Fetch and validate
        </Button>
      </div>
    );
  }
}
