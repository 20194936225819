import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';

export default class DurationInput extends Component {
    static defaultState = () => '';

    static toState(value) {
        if (value.startsWith('PT')) {
            const times = value.replace(/^PT/, '').replace(/M$/, '').split('H');
            const res = times.length > 1
                ? times[0] + ':' + times[1]
                : '00:' + times[0];
            return res;
        }
        else {
            return '';
        }
    }

    collect() {
        const { values } = this.props;
        if (values) {
            let time = values;          
            return time;
        } else {
            return null;
        }
    }

    render() {
        const { name, values, updateValues, path } = this.props;

        return <TextField
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
            value={values}
            type="time"
            onChange={e => updateValues(path, e.target.value)}
            label={name}
        />
    }
}
