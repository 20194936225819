import React, { Component } from 'react';
import validUrl from 'valid-url';
import TextField from '@material-ui/core/TextField';
import validateText from "./validateText";

export default class UrlInput extends Component {
    static defaultState = () => '';

    collect() {
        return validateText(this.props.values);
    }

    onClick = () => {
        const {path, updateValues} = this.props;

        navigator.clipboard.readText().then(text => {
            if (validUrl.isUri(text)) {
                updateValues(path, text);
            }
        });
    };

    render() {
        const {name, values, updateValues, path} = this.props;

        return <TextField
            fullWidth
            value={ values }
            onClick={ this.onClick }
            onChange={ e => updateValues(path, e.target.value) }
            label={ name }
        />
    }
}
