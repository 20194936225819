import TextInput from "../ui/TextInput";
import DurationInput from "../ui/DurationInput";
import UrlInput from "../ui/UrlInput";
import DateInput from "../ui/DateInput";
import SelectboxComp from "../ui/SelectboxComp";
import AsyncSelectboxComp from "../ui/AsyncSelectboxComp";
import MultiSelectBoxComp from "../ui/MultiSelectBoxComp";
import AsyncCreatableSelectbox from "../ui/AsyncCreatableSelectbox";
import SubHeaderComp from "../ui/SubHeaderComp";
import NoteComp from "../ui/NoteComp";
import UnibetDataFetch from "../ui/UnibetDataFetch";
/*
 * Recursive tree structure. A node can be one of 3 things:
 * - A root node, which is a type of input (eg {Text: TextInput}, {Date: DateInput})
 *
 * - A collection of other node types
 * TimeEvent: {
 *     _isCollection: true,
 *     date: 'Date',
 *     event: 'Text'
 * }
 *
 * - An array of other node types:
 * Calendar: ['TimeEvent'].
 *
 *
 * From this simple example: A Calendar would generate an array of TimeEvent-inputs, which
 * in turn is a collection of one Date input and one Text input. The Date and Text input would
 * resolve to the UI classes DateInput and TextInput.
 */
const types = {
    Text: TextInput,
    HowToStep: {
        _isCollection: true,
        '@type': 'HowToStep',
        text: 'Text',
    },
    URL: UrlInput,
    Duration: DurationInput,
    AsyncSelectbox:AsyncSelectboxComp,
    Selectbox: SelectboxComp,
    Date: DateInput,
    MultiSelectBox:MultiSelectBoxComp,
    AsyncCreatableSelectbox:AsyncCreatableSelectbox,
    SubHeader:SubHeaderComp,
    Note: NoteComp,
    Unibet: UnibetDataFetch,
    ImageObject: {
        _isCollection: true,
        '@type': 'ImageObject',
        url: 'URL',
        width: 'Text',
        height: 'Text',
    },
    Recipe: {
        _isCollection: true,
        '@type': 'Recipe',
        '+prepTime': 'PT0M',
        name: 'Text',
        description: 'Text',
        image: 'ImageObject',
        datePublished: 'Date',
        author: 'Text',
        cookTime: 'Duration',
        recipeCategory: 'Text',
        recipeCuisine: 'Text',
        keywords: 'Text',
        recipeYield: 'Text',
        recipeIngredient: ['Text'],
        recipeInstructions: ['HowToStep'],
    },
    Appearance: {
        _isCollection: true,
        '@type': 'T2',
        color: 'Text',
        quality: 'Text',
    },
    Like: {
        _isCollection: true,
        '@type': 'Like',
        activity: 'Text'
    },
    Cat: {
        _isCollection: true,
        '@type': 'Cat',
        firstName: 'Text',
        lastName: 'Text',
        appearance: 'Appearance',
        likes: ['Like'],
    },
    ExpertAdvise: {
        _isCollection: true,
        template:'Selectbox',
        template_alias: 'Text',
        publisher: 'Text',
        expertname: 'Selectbox',
        tags:'MultiSelectBox',
        'Start Page':'SubHeader',
        publish_date: 'Date',
        publish_time: 'Duration',
        publish_article_title: 'Selectbox',
        publish_article_lead: 'Selectbox',
        publish_article_text: 'Selectbox',
        publish_article_image_startpage_position:'Selectbox',
        publish_article_sticky: 'Selectbox', 
        'Article Page':'SubHeader',
        // 'Note : If staging_article is NO then article will not be published in Production':'Note',
        staging_article: 'Selectbox',
        publish_article_image_detailpage:'Selectbox',
        'Fetch Game from Unibet':'SubHeader',
        unibet:'Unibet',
        games: ['Game'],
    },
    Game: {
        _isCollection: true,
        "game-start-date": 'Date',
        sport: 'Selectbox',
        'league':'Selectbox',
        type: 'AsyncSelectbox',
        value: 'Text',
        'betting-odds':'Text',
        'betting-partner':'Text',
        'betting-link':'Text',
        "game-comment": 'Text',
        "team-a": 'Selectbox',        
        "team-a-comment": 'Text',
        "team-a-grade": 'Selectbox',
        "team-b": 'Selectbox',
        "team-b-comment": 'Text',
        "team-b-grade": 'Selectbox',
        "eventid":'Text',
        "team_a_participant_id":'Text',
        "team_b_participant_id":'Text',      
        "unibet-eventid":'Text',  
    },
    customGame: {
        _isCollection: true,
        "date": 'Date',
        'sport':'Selectbox',
        'league':'Text',
        'team-a': 'Text',
        'team-b': 'Text',
    }
};

/*

{
    "@type": "Cat",
    "firstName": "Miyamoto",
    "lastName": "Musashi",
    "appearance": {
        "@type": "T2",
        "color": "Grey",
        "quality": "Magnificent"
    },
    "likes": [
        {
            "@type": "Like",
            "activity": "Cuddling"
        },
        {
            "@type": "Like",
            "activity": "Eating"
        },
        {
            "@type": "Like",
            "activity": "Getting abused by puppies"
        }
    ]
}

*/

export default types;
