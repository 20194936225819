import React, { Component } from "react";
// import AsyncSelect from 'react-select/lib/Async';
import AsyncCreatableSelect from "react-select/async-creatable";
import constants from "../util/constants";
import Axios from "axios";
import _ from "lodash";

const getOptions = inputValue => {
  const URL = `${constants.AB_URL}v1/search_league?sport=football&date=2019-03-17&league_name=${inputValue}`;
 let x= Axios.get(URL)
    .then(resp => {
      return resp.data;
    })
    .then(data => {
      let options = [];
      options = data.map((val, index) => {
        let x = { label: val, value: val };
        return x;
      });
      return options.filter(i =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    })
    .catch(err => console.log(err));
    return x;
};

export default function AsyncCreatableSelectbox() {
  return <AsyncCreatableSelect cacheOptions loadOptions={getOptions}/>;
}
