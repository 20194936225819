import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
    appBar: {
        padding: theme.spacing.unit * 2,
    },
    main: {
        marginTop: theme.spacing.unit * 12,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    aside: {
        marginTop: theme.spacing.unit * 12,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
    paper: {
        marginTop: theme.spacing.unit * 1,
        marginBottom: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 2,
    },
    smallPaper: {
        marginTop: theme.spacing.unit * 0.5,
        marginBottom: theme.spacing.unit * 1.5,
        padding: theme.spacing.unit * 1.5,
    },
    stepper: {
        padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: 0,
        marginBottom: 0,
        marginRight: theme.spacing.unit,
    },
    pre: {
        flex: 1,
        display: 'flex',
        overflow: 'auto',
    },
    code: {
        minHeight: 'min-content',
        display: 'flex',
        fontSize: '8px',
    },
    flexRow: {
        display: 'flex',
    },
    preFill: {
        display: 'flex',
        marginRight: 'auto',
    },
    appBarTitle: {
        marginRight: 'auto',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
    marginRight: {
        marginRight: theme.spacing.unit * 2,
    },
    copyToClipBoardButton:{
        backgroundColor: '#f50047c7',
        height: '33px',
        border: 'none',
        cursor: 'pointer',
        float: 'right',
        color:'white'
    }
});

export default styles;
